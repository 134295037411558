import {
  Scenario,
  SearchSessionData,
  SessionData,
} from '@yaak/components/services/api/api'
import { getDriveType } from './devices'
import React, { Dispatch, SetStateAction } from 'react'
import { toastType } from '@yaak/components/src/Toast/Toast'
import Progress from '@yaak/components/src/Progress'
import Icon from '@yaak/components/src/Icon'
import DataStatus from '../components/DataStatus'
import { transpose } from './common'
import { IconSizes } from '@yaak/components/src/Icon/Icon'
import { NavigateFunction } from 'react-router-dom'
import {
  formatTimestamp,
  formatTimestampToTime,
  toDurationPrecise,
  toHoursMinutesSeconds,
} from './time'
import {
  BitRateGridElement,
  GridCopyIconElement,
  GridDriverElement,
  GridDriveStatusElement,
  GridIconElement,
  GridInstructorElement,
  GridKitElement,
  GridPartnerElement,
  GridTextElement,
  GridVehicleElement,
  MemoGridLightningBadge,
  MemoGridWeatherIcons,
} from '@yaak/components/src/Grid/GridElements'
import { Version } from '@yaak/components/src/types'
import Badge from '@yaak/components/src/Badge'
import { getSafetyScoreBadgeType } from './score'
import { STATUS_CODES } from './statusCodes'

export const HEADERS = [
  'Time',
  'Partner',
  'Type',
  'Vehicle',
  'Kit ID',
  'Driver',
  'Instructor',
  'Status',
  'Data',
  'Bitrate',
  '',
]

const DSADMIN_HEADERS = ['Time', 'Type', 'Vehicle', 'Driver', 'Data']
const DSADMIN_HEADERS_STUDENTS = ['Time', 'Type', 'Vehicle', 'Data']
const DSADMIN_HEADERS_VEHICLES = [
  'Time',
  'Type',
  'Driver',
  'Instructor',
  'Data',
]
export const getNutronHeaders = (yaakAdmin: boolean) => [
  'Session ID',
  'Annotations',
  'Route tags',
  'Lighting',
  'Weather',
  'Policy',
  'Location',
  yaakAdmin ? 'Organization' : 'Driver',
  'Start',
  'Duration',
  'Hardware ID',
  'Data',
  '',
]

export const getNutronSearchScenariosHeaders = () => [
  'Session ID',
  'Scenarios',
  'Annotations',
  'Route tags',
  'Lighting',
  'Weather',
  'Policy',
  'Location',
  'Start',
  'Duration',
  'Hardware ID',
  '',
]

export const getScenariosHeaders = () => [
  'Score',
  'Lighting',
  'Weather',
  'Policy',
  'Location',
  'Start',
  'Duration',
  'Session ID',
  'Hardware ID',
  '',
]

const getCopyLink = (
  session: SessionData | Scenario | SearchSessionData,
  context: string | null
) => {
  const sessionData = session as SessionData
  const sessionScenario = session as Scenario

  const id = sessionData.id || sessionScenario.sessionId
  const { startOffset, endOffset } = sessionScenario
  const params =
    startOffset !== undefined && endOffset !== undefined
      ? `?begin=${startOffset}&end=${endOffset}&context=${parseInt(
          context || '0'
        )}&offset=${
          startOffset > 0 ? startOffset - parseInt(context || '0') : startOffset
        }`
      : ''
  const redirectUrl = `/dataset/session-logs/${id}${params}`
  const baseUrl = window.location.protocol + '//' + window.location.host
  return `${baseUrl}${redirectUrl}`
}

export type statusDataType = {
  metadataStatus?: number
  fullVideoStatus?: number
  safetyAIStatus?: number
}

type resultType = {
  value: number
  data: statusDataType
}

interface MappedDrivesTypeOptions {
  withPartner: boolean
  withInstructor: boolean
  withKit: boolean
  dsAdmin: boolean
  withDriver: boolean
  withVehicle: boolean
  fixedColumns: number
  nutron: boolean
  yaakAdmin: boolean
  scenarios: boolean
  searchSessions: boolean
  context: string | null
}

export const getDataComponent = (
  result: resultType,
  dsAdmin = false,
  version: Version = Version.v1
) => {
  return result.value === 10
    ? {
        text: (
          <DataStatus
            icon={<Progress percentage={result.value} />}
            data={result.data}
            type={2}
            dsAdmin={dsAdmin}
            version={version}
          />
        ),
        popover: true,
      }
    : result.value === 33
    ? {
        text: (
          <DataStatus
            icon={<Progress percentage={result.value} />}
            data={result.data}
            type={2}
            dsAdmin={dsAdmin}
            version={version}
          />
        ),
        popover: true,
      }
    : result.value === 67
    ? {
        text: (
          <DataStatus
            icon={<Progress percentage={result.value} />}
            data={result.data}
            type={2}
            dsAdmin={dsAdmin}
            version={version}
          />
        ),
        popover: true,
      }
    : result.value === 1
    ? {
        text: (
          <DataStatus
            icon={
              <Icon
                name={version === Version.v2 ? 'CheckmarkCircle' : 'Success'}
                version={version}
                size={IconSizes.medium}
              />
            }
            data={result.data}
            type={3}
            dsAdmin={dsAdmin}
            version={version}
          />
        ),
        popover: true,
      }
    : result.value === -1
    ? {
        text: (
          <DataStatus
            icon={
              <Icon name={'Error'} version={version} size={IconSizes.medium} />
            }
            data={result.data}
            type={4}
            dsAdmin={dsAdmin}
            version={version}
          />
        ),
        popover: true,
      }
    : result.value === 0
    ? {
        text: (
          <DataStatus
            icon={<Progress />}
            data={result.data}
            type={result.value}
            dsAdmin={dsAdmin}
            version={version}
          />
        ),
        popover: true,
      }
    : null
}

export const getSessionStatus = (data: statusDataType) => {
  const { metadataStatus, fullVideoStatus, safetyAIStatus } = data
  return ((metadataStatus === undefined || metadataStatus === null) &&
    fullVideoStatus === STATUS_CODES.PROCESSING &&
    safetyAIStatus === STATUS_CODES.PROCESSED) ||
    ((metadataStatus === undefined || metadataStatus === null) &&
      fullVideoStatus === STATUS_CODES.PROCESSED &&
      safetyAIStatus === STATUS_CODES.PROCESSING) ||
    (metadataStatus === STATUS_CODES.PROCESSING &&
      (fullVideoStatus === undefined || fullVideoStatus === null) &&
      safetyAIStatus === STATUS_CODES.PROCESSED) ||
    (metadataStatus === STATUS_CODES.PROCESSED &&
      (fullVideoStatus === undefined || fullVideoStatus === null) &&
      safetyAIStatus === STATUS_CODES.PROCESSING) ||
    (metadataStatus === STATUS_CODES.PROCESSING &&
      fullVideoStatus === STATUS_CODES.PROCESSED &&
      (safetyAIStatus === undefined || safetyAIStatus === null)) ||
    (metadataStatus === STATUS_CODES.PROCESSED &&
      fullVideoStatus === STATUS_CODES.PROCESSING &&
      (safetyAIStatus === undefined || safetyAIStatus === null))
    ? {
        value: 10,
        data: {
          metadataStatus,
          fullVideoStatus,
          safetyAIStatus,
        },
      }
    : (metadataStatus === STATUS_CODES.PROCESSED &&
        fullVideoStatus === STATUS_CODES.PROCESSING &&
        safetyAIStatus === STATUS_CODES.UPLOADING) ||
      (metadataStatus === STATUS_CODES.PROCESSED &&
        fullVideoStatus === STATUS_CODES.UPLOADING &&
        safetyAIStatus === STATUS_CODES.PROCESSING) ||
      (metadataStatus === STATUS_CODES.PROCESSING &&
        fullVideoStatus === STATUS_CODES.PROCESSED &&
        safetyAIStatus === STATUS_CODES.UPLOADING) ||
      (metadataStatus === STATUS_CODES.PROCESSING &&
        fullVideoStatus === STATUS_CODES.PROCESSED &&
        safetyAIStatus === STATUS_CODES.WAITING) ||
      (metadataStatus === STATUS_CODES.UPLOADING &&
        fullVideoStatus === STATUS_CODES.PROCESSED &&
        safetyAIStatus === STATUS_CODES.PROCESSING) ||
      (metadataStatus === STATUS_CODES.PROCESSING &&
        fullVideoStatus === STATUS_CODES.UPLOADING &&
        safetyAIStatus === STATUS_CODES.PROCESSED) ||
      (metadataStatus === STATUS_CODES.UPLOADING &&
        fullVideoStatus === STATUS_CODES.PROCESSING &&
        safetyAIStatus === STATUS_CODES.PROCESSED) ||
      (metadataStatus === STATUS_CODES.UPLOADING &&
        fullVideoStatus === STATUS_CODES.PROCESSING &&
        safetyAIStatus === STATUS_CODES.WAITING) ||
      (metadataStatus === STATUS_CODES.PROCESSED &&
        fullVideoStatus === STATUS_CODES.PROCESSING_FAILED &&
        safetyAIStatus === STATUS_CODES.UPLOADING) ||
      (metadataStatus === STATUS_CODES.PROCESSED &&
        fullVideoStatus === STATUS_CODES.UPLOADING &&
        safetyAIStatus === STATUS_CODES.PROCESSING_FAILED) ||
      (metadataStatus === STATUS_CODES.PROCESSING_FAILED &&
        fullVideoStatus === STATUS_CODES.PROCESSED &&
        safetyAIStatus === STATUS_CODES.UPLOADING) ||
      (metadataStatus === STATUS_CODES.PROCESSING_FAILED &&
        fullVideoStatus === STATUS_CODES.PROCESSED &&
        safetyAIStatus === STATUS_CODES.WAITING) ||
      (metadataStatus === STATUS_CODES.UPLOADING &&
        fullVideoStatus === STATUS_CODES.PROCESSED &&
        safetyAIStatus === STATUS_CODES.PROCESSING_FAILED) ||
      (metadataStatus === STATUS_CODES.PROCESSING_FAILED &&
        fullVideoStatus === STATUS_CODES.UPLOADING &&
        safetyAIStatus === STATUS_CODES.PROCESSED) ||
      (metadataStatus === STATUS_CODES.UPLOADING &&
        fullVideoStatus === STATUS_CODES.PROCESSING_FAILED &&
        safetyAIStatus === STATUS_CODES.PROCESSED) ||
      (metadataStatus === STATUS_CODES.UPLOADING &&
        fullVideoStatus === STATUS_CODES.PROCESSING_FAILED &&
        safetyAIStatus === STATUS_CODES.WAITING)
    ? {
        value: 33,
        data: {
          metadataStatus,
          fullVideoStatus,
          safetyAIStatus,
        },
      }
    : (metadataStatus === STATUS_CODES.PROCESSED &&
        fullVideoStatus === STATUS_CODES.PROCESSED &&
        safetyAIStatus === STATUS_CODES.PROCESSING) ||
      (metadataStatus === STATUS_CODES.PROCESSED &&
        fullVideoStatus === STATUS_CODES.PROCESSING &&
        safetyAIStatus === STATUS_CODES.PROCESSED) ||
      (metadataStatus === STATUS_CODES.PROCESSING &&
        fullVideoStatus === STATUS_CODES.PROCESSED &&
        safetyAIStatus === STATUS_CODES.PROCESSED)
    ? {
        value: 67,
        data: {
          metadataStatus,
          fullVideoStatus,
          safetyAIStatus,
        },
      }
    : metadataStatus === STATUS_CODES.PROCESSED &&
      fullVideoStatus === STATUS_CODES.PROCESSED &&
      safetyAIStatus === STATUS_CODES.PROCESSED
    ? {
        value: 1,
        data: {
          metadataStatus,
          fullVideoStatus,
          safetyAIStatus,
        },
      }
    : metadataStatus === STATUS_CODES.PROCESSING_FAILED ||
      fullVideoStatus === STATUS_CODES.PROCESSING_FAILED ||
      safetyAIStatus === STATUS_CODES.PROCESSING_FAILED
    ? {
        value: -1,
        data: {
          metadataStatus,
          fullVideoStatus,
          safetyAIStatus,
        },
      }
    : {
        value: 0,
        data: {
          metadataStatus,
          fullVideoStatus,
          safetyAIStatus,
        },
      }
}

const getRows = (
  data: SessionData[],
  activeDrivesIds: string[],
  setShowToast: Dispatch<SetStateAction<toastType | null>>,
  navigate: NavigateFunction,
  matches: boolean,
  options: MappedDrivesTypeOptions
) => {
  return data.map((session: SessionData) => {
    const row = []

    row.push(
      GridTextElement({
        text: formatTimestamp(session.startTimestamp),
        ellipsis: matches,
      })
    )
    options?.withPartner &&
      row.push(
        GridPartnerElement({
          partner: session.partner,
          ellipsis: true,
          navigate,
        })
      )
    row.push(
      GridTextElement({
        ellipsis: matches,
        text: getDriveType(session.driveType),
      })
    )
    options?.withVehicle &&
      row.push(
        GridVehicleElement({
          vin: session.vin,
          dongleId: session.dongleId,
          ellipsis: matches,
          navigate,
          dsAdmin: options?.dsAdmin,
        })
      )
    options?.withKit &&
      row.push(
        GridKitElement({
          id: session.dongleId,
          ellipsis: matches,
          navigate,
        })
      )
    options?.withDriver &&
      row.push(
        GridDriverElement({
          driver: session.driver,
          ellipsis: matches,
          navigate,
          dsAdmin: options.dsAdmin,
        })
      )
    options?.withInstructor &&
      row.push(
        GridInstructorElement({
          session,
          ellipsis: matches,
          navigate,
          dsAdmin: options.dsAdmin,
        })
      )
    !options?.dsAdmin &&
      row.push(
        GridDriveStatusElement({
          session,
          activeDrivesIds,
        })
      )
    row.push(
      getDataComponent(
        getSessionStatus({
          metadataStatus: session.metadataStatus,
          fullVideoStatus: session.fullVideoStatus,
          safetyAIStatus: session.safetyAIStatus,
        }),
        options?.dsAdmin
      )
    )
    !options?.dsAdmin &&
      row.push(
        BitRateGridElement({
          session,
        })
      )
    !options?.dsAdmin &&
      row.push(
        GridCopyIconElement({
          data: session,
          name: 'drive',
          setShowToast,
          text: 'Drive ID copied to clipboard',
        })
      )
    return row
  })
}

const getRowsNutron = (
  data: SessionData[] | SearchSessionData[],
  setShowToast: Dispatch<SetStateAction<toastType | null>>,
  navigate: NavigateFunction,
  matches: boolean,
  options: MappedDrivesTypeOptions
) => {
  return data.map((session: SessionData | SearchSessionData) => {
    const row = []

    row.push(
      GridTextElement({
        text:
          (session as SessionData).canonicalName ||
          (session as SearchSessionData).sessionCanonicalName,
        ellipsis: matches,
        version: Version.v2,
      })
    )

    if (options.searchSessions) {
      row.push(
        GridTextElement({
          text: (session as SearchSessionData).scenarioCount || '-',
          ellipsis: false,
        })
      )
    }

    row.push(
      GridIconElement({
        text: (session as SessionData).incidentCount || '-',
        version: Version.v2,
      })
    )
    row.push(
      GridIconElement({
        text: (session as SessionData).curriculumCount?.toString() || '-',
        version: Version.v2,
      })
    )
    // TODO: Remove this condition when BE fixes this in sessions API
    const lightingConditions =
      (session as SessionData).lightningConditions ||
      (session as SearchSessionData).lightingConditions
    row.push(
      GridTextElement({
        text:
          lightingConditions?.length > 0 ? (
            <MemoGridLightningBadge lightningConditions={lightingConditions} />
          ) : (
            '-'
          ),
        version: Version.v2,
      })
    )
    row.push(
      GridTextElement({
        text:
          session.weatherConditions?.length > 0 ? (
            <MemoGridWeatherIcons
              weatherConditions={session.weatherConditions}
            />
          ) : (
            '-'
          ),
        version: Version.v2,
      })
    )
    const driveType =
      (session as SessionData).driveType ||
      (session as SearchSessionData).sessionTypeInt
    row.push(
      GridTextElement({
        ellipsis: matches,
        text: getDriveType(driveType),
        version: Version.v2,
      })
    )
    row.push(
      GridTextElement({
        text: session.location.area || '-',
        ellipsis: true,
        version: Version.v2,
      })
    )
    if (!options.searchSessions) {
      options.yaakAdmin
        ? row.push(
            GridTextElement({
              text: (session as SessionData).partner?.name || '-',
              ellipsis: true,
              version: Version.v2,
            })
          )
        : row.push(
            GridDriverElement({
              driver: (session as SessionData).driver,
              ellipsis: matches,
              navigate,
              version: Version.v2,
            })
          )
    }
    row.push(
      GridTextElement({
        text: formatTimestampToTime(session.startTimestamp),
        ellipsis: matches,
        version: Version.v2,
      })
    )
    row.push(
      GridIconElement({
        text: toDurationPrecise(session.startTimestamp, session.endTimestamp),
        version: Version.v2,
      })
    )
    row.push(
      GridTextElement({
        text:
          (session as SessionData).dongleId ||
          (session as SearchSessionData).sessionDongleId,
        ellipsis: matches,
        version: Version.v2,
      })
    )
    if (!options.searchSessions) {
      row.push(
        getDataComponent(
          getSessionStatus({
            metadataStatus: (session as SessionData).metadataStatus,
            fullVideoStatus: (session as SessionData).fullVideoStatus,
            safetyAIStatus: (session as SessionData).safetyAIStatus,
          }),
          false,
          Version.v2
        )
      )
    }
    row.push(
      GridCopyIconElement({
        data: session,
        name: '',
        tooltipText: 'Copy link',
        copyText: getCopyLink(session, options.context),
        setShowToast,
        text: 'Link copied to clipboard',
        version: Version.v2,
      })
    )
    return row
  })
}

const getRowsScenarios = (
  data: Scenario[],
  setShowToast: Dispatch<SetStateAction<toastType | null>>,
  matches: boolean,
  options: MappedDrivesTypeOptions
) => {
  return data.map((scenario: Scenario) => {
    const row = []

    row.push(
      GridTextElement({
        text: scenario.safetyScore ? (
          <Badge
            label={(scenario.safetyScore * 100).toFixed(0) || '-'}
            type={getSafetyScoreBadgeType(scenario.safetyScore)}
          />
        ) : (
          '-'
        ),
        version: Version.v2,
      })
    )
    row.push(
      GridTextElement({
        text:
          scenario.lightingConditions?.length > 0 ? (
            <MemoGridLightningBadge
              lightningConditions={scenario.lightingConditions}
            />
          ) : (
            '-'
          ),
        version: Version.v2,
      })
    )
    row.push(
      GridTextElement({
        text:
          scenario.weatherConditions?.length > 0 ? (
            <MemoGridWeatherIcons
              weatherConditions={scenario.weatherConditions}
            />
          ) : (
            '-'
          ),
        version: Version.v2,
      })
    )
    row.push(
      GridTextElement({
        ellipsis: matches,
        text: getDriveType(scenario.sessionTypeInt),
        version: Version.v2,
      })
    )
    row.push(
      GridTextElement({
        text: scenario.location.area || '-',
        ellipsis: true,
        version: Version.v2,
      })
    )
    row.push(
      GridIconElement({
        text: formatTimestampToTime(scenario.startTimestamp),
        version: Version.v2,
      })
    )
    row.push(
      GridIconElement({
        text: toHoursMinutesSeconds(
          (scenario.endOffset - scenario.startOffset) * 1000
        ),
        version: Version.v2,
      })
    )
    row.push(
      GridTextElement({
        text: scenario.sessionCanonicalName,
        ellipsis: matches,
        version: Version.v2,
      })
    )
    row.push(
      GridTextElement({
        text: scenario.sessionDongleId,
        ellipsis: matches,
        version: Version.v2,
      })
    )
    row.push(
      GridCopyIconElement({
        data: scenario,
        tooltipText: 'Copy link',
        copyText: getCopyLink(scenario, options.context),
        name: '',
        setShowToast,
        text: 'Link copied to clipboard',
        version: Version.v2,
      })
    )
    return row
  })
}

interface MappedDrivesType {
  sessionsData: SessionData[] | Scenario[]
  activeSessionsData: SessionData[]
  setShowToast: Dispatch<SetStateAction<toastType | null>>
  totalCount?: number
  matches: boolean
  classes?: any
  options?: MappedDrivesTypeOptions
  navigate: NavigateFunction
}

const getHeaders = (options?: MappedDrivesTypeOptions) => {
  let headers = HEADERS
  if (options?.scenarios) {
    headers = getScenariosHeaders()
  } else if (options?.searchSessions) {
    headers = getNutronSearchScenariosHeaders()
  } else if (options?.nutron) {
    headers = getNutronHeaders(options.yaakAdmin)
  } else if (options?.dsAdmin) {
    headers = !options?.withVehicle
      ? DSADMIN_HEADERS_VEHICLES
      : options?.withDriver
      ? DSADMIN_HEADERS
      : DSADMIN_HEADERS_STUDENTS
  } else if (
    !options?.withPartner &&
    !options?.withInstructor &&
    !options?.withKit
  ) {
    headers = HEADERS.filter((header, idx) => idx !== 1)
      .filter((header, idx) => idx !== 5)
      .filter((header, idx) => idx !== 3)
  } else if (!options?.withPartner) {
    headers = HEADERS.filter((header, idx) => idx !== 1)
  } else if (!options?.withInstructor) {
    headers = HEADERS.filter((header, idx) => idx !== 6)
  } else if (!options?.withKit) {
    headers = HEADERS.filter((header, idx) => idx !== 4)
  }
  return headers
}

export const getMappedDrives = ({
  sessionsData,
  activeSessionsData = [],
  setShowToast,
  totalCount,
  matches,
  options = {
    withPartner: true,
    withInstructor: true,
    withKit: true,
    dsAdmin: false,
    withDriver: true,
    withVehicle: true,
    fixedColumns: 0,
    nutron: false,
    yaakAdmin: false,
    scenarios: false,
    searchSessions: false,
    context: '',
  },
  navigate,
}: MappedDrivesType) => {
  const activeDrivesIds = activeSessionsData.map(
    (activeSession) => activeSession.id
  )

  const ids = activeSessionsData
    .concat(sessionsData as SessionData[])
    .map((session) => session.id)

  const columns = transpose(
    options?.scenarios
      ? getRowsScenarios(
          sessionsData as Scenario[],
          setShowToast,
          matches,
          options
        )
      : options.nutron
      ? getRowsNutron(
          sessionsData as SessionData[] | SearchSessionData[],
          setShowToast,
          navigate,
          matches,
          options
        )
      : getRows(
          sessionsData as SessionData[],
          activeDrivesIds,
          setShowToast,
          navigate,
          matches,
          options
        )
  )

  columns.map((row: any, i: number) =>
    getHeaders(options).map((header, k) => i === k && row.unshift(header))
  )

  return {
    options: {
      fixedColumns: options?.fixedColumns || 4,
      activeDrivesIds,
      active: activeDrivesIds.map((id) => ids.indexOf(id)),
      totalCount,
    },
    rows: columns,
  }
}
