import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import style from './style.less'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import Typography, {
  TypographySizes,
  TypographyTypes,
} from '@yaak/components/src/Typography/Typography'
import SaveEditQueryDialog from './dialogs/SaveEditQueryDialog'
import classNames from 'classnames'
import WarningDialog from '../WarningDialog'
import {
  deleteSavedQuery,
  editSavedQuery,
  getSavedQueries,
  saveSearchQuery,
} from '../../services/api/api'
import { ToastContext, ToastContextType } from '../../context/toastContext'
import { useAuth0 } from '@auth0/auth0-react'
import { areParamsValid, parseSearchParams } from '../DrivesOverview/utils'
import {
  GROUPS_MAPPING,
  OPERATORS_KEYS,
  queryToTags,
  UNIT_MAPPING,
  UNIT_MAPPING_KEYS,
} from './utils'
import ToggleButton, {
  ToggleButtonTypes,
  ToggleColors,
} from '@yaak/components/src/ToggleButton'
import SearchQueryTag from './SearchQueryTag'
import { formatDateRange } from '@yaak/admin/src/helpers/time'
import { useShallow } from 'zustand/react/shallow'
import { useTagsStore } from '../stores/Tags'
import NLSAutocomplete from './NLSAutocomplete'

const uuid = require('uuid')

export type MergedListKey = 'key' | 'value' | 'savedQuery' | 'unit' | 'group'

export interface SavedQuery {
  id?: string
  conditions?: any
  value: string
  group?: string
  onCopy: (value: string) => void
  onEdit: (savedQuery: SavedQuery) => void
  onDelete: (savedQuery: SavedQuery) => void
  onClick: (savedQuery: SavedQuery) => void
}

export enum ViewTypeValues {
  list = 'list',
  grid = 'grid',
  sessionLogs = 'session-logs',
  scenarios = 'scenarios',
}

interface SearchQueryBarProps {
  token: string
  view: string
  onViewChange: (value: string) => void
  totalSessions?: number
}

const SearchQueryBar = ({
  token,
  view,
  onViewChange,
  totalSessions,
}: SearchQueryBarProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [, , pathnameValue, viewType] = location.pathname.split('/')
  const contextRef = useRef<HTMLInputElement>(null)
  const textFieldRef = useRef<HTMLInputElement | null>(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const [context, setContext] = useState<string>('5s')
  const [contextEdited, setContextEdited] = useState<boolean>(false)
  const [showSaveQueryModal, setShowSaveQueryModal] = useState(false)
  const [savedQueries, setSavedQueries] = useState<SavedQuery[]>([])
  const [savedQueryToDelete, setSavedQueryToDelete] = useState<any>()
  const [savedQueryToEdit, setSavedQueryToEdit] = useState<any>()
  const [range] = useState<[Date, Date] | null>()
  const { setShowToast } = useContext(ToastContext) as ToastContextType
  const { user } = useAuth0()
  const { tags, update } = useTagsStore(
    useShallow((state) => ({
      tags: state.tags,
      update: state.update,
    }))
  )

  const getQueries = async () => {
    const savedQueriesResponse = await getSavedQueries({
      userId: user?.sub,
      token,
      onAlert: setShowToast,
    })
    if (savedQueriesResponse.data.length > 0) {
      const savedQueries = savedQueriesResponse.data.map((savedQuery) => {
        const tags = queryToTags(savedQuery.conditions.query)
          .concat(queryToTags(savedQuery.conditions.filters))
          .join('&')
        return {
          id: savedQuery.id,
          conditions: savedQuery.conditions,
          value: savedQuery.name,
          onClick: (query: SavedQuery) => {
            const tags = queryToTags(query.conditions.query)
              .concat(queryToTags(query.conditions.filters))
              .join('&')
            tags &&
              navigate(
                `/dataset/scenarios/${viewType}?context=${context}&q=${encodeURIComponent(
                  tags
                )}`,
                { replace: true }
              )
          },
          onCopy: () => {
            navigator.clipboard.writeText(
              `${
                window.location.origin
              }/dataset/scenarios/${viewType}/?context=${context}&q=${encodeURIComponent(
                tags
              )}`
            )
          },
          onEdit: (savedQuery: SavedQuery) => {
            setSavedQueryToEdit(savedQuery)
          },
          onDelete: (savedQuery: SavedQuery) => {
            setSavedQueryToDelete(savedQuery)
          },
        }
      })
      setSavedQueries(savedQueries)
    }
  }

  useEffect(() => {
    token && user && getQueries()
  }, [token, user])

  const createTags = useCallback(
    (value: string | null) => {
      if (value) {
        const type = value.split(' ')[0].replace(/ /g, '')
        const begin = searchParams.getAll('begin')[0]
        const end = searchParams.getAll('end')[0]
        const updatedTags = [
          {
            group: GROUPS_MAPPING[type as OPERATORS_KEYS] || '',
            value:
              type === 'date'
                ? begin && end
                  ? `date = ${formatDateRange(new Date(begin), new Date(end))}`
                  : 'date ='
                : value,
            key: `${uuid.v4()}_${value}`,
            savedQuery: false,
            unit: UNIT_MAPPING[type as UNIT_MAPPING_KEYS] || '',
          },
        ]
        if (tags) {
          update(tags.concat(updatedTags))
        } else {
          update(updatedTags)
        }
      }
    },
    [tags]
  )

  useEffect(() => {
    if (tags && tags.length > 0) {
      const begin = searchParams.getAll('begin')[0]
      const end = searchParams.getAll('end')[0]
      searchParams.set(
        'q',
        tags.map((tag) => encodeURIComponent(tag.value)).join('&')
      )
      setSearchParams(searchParams)
      const queryParam = searchParams.get('q')
      if (queryParam) {
        const validParams = areParamsValid(decodeURIComponent(queryParam))
        if (validParams) {
          begin && end
            ? navigate(
                `/dataset/scenarios/grid?q=${encodeURIComponent(
                  queryParam
                )}&context=${context}&begin=${begin}&end=${end}`
              )
            : navigate(
                `/dataset/scenarios/grid?q=${encodeURIComponent(
                  queryParam
                )}&context=${context}`
              )
        }
      }
    } else {
      navigate('/')
    }
  }, [tags, context, searchParams])

  useEffect(() => {
    if (!tags) {
      const params = searchParams.getAll('q')[0]
      const begin = searchParams.getAll('begin')[0]
      const end = searchParams.getAll('end')[0]
      const splitParams = params ? params.split('&') : []
      const createdTags = splitParams.map((value) => {
        const type = decodeURIComponent(value).split(' ')[0].replace(/ /g, '')
        return {
          group: GROUPS_MAPPING[type as OPERATORS_KEYS] || '',
          value:
            type === 'date'
              ? begin && end
                ? `date = ${formatDateRange(new Date(begin), new Date(end))}`
                : 'date ='
              : decodeURIComponent(value),
          key: `${uuid.v4()}_${decodeURIComponent(value)}`,
          savedQuery: false,
          unit: UNIT_MAPPING[type as UNIT_MAPPING_KEYS] || '',
        }
      })
      createdTags.length > 0 && update(createdTags)
    }
  }, [tags, searchParams])

  useEffect(() => {
    const params = searchParams.getAll('context')[0]
    const newContext = params || context
    setContext(newContext)
    searchParams.set('context', newContext)
    setSearchParams(searchParams)
  }, [searchParams, context])

  const onContextChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setContext(event.target.value)
      searchParams.set('context', event.target.value)
      setSearchParams(searchParams)
      setContextEdited(true)
    },
    [searchParams]
  )

  const onContextKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Tab' || event.key === 'Enter') {
        const contextVal = context || '5s'
        const newContext =
          contextVal.indexOf('s') === -1 ? contextVal + 's' : contextVal
        setContext(newContext)
        searchParams.set('context', newContext)
        contextRef.current?.blur()
      }
    },
    [context]
  )

  const onContextFocusOut = useCallback(() => {
    if (!context) {
      setContext('5s')
    }
  }, [context])

  const onClearFilters = useCallback(() => {
    update()
    searchParams.delete('q')
    setSearchParams(searchParams)
    navigate(`/dataset/session-logs/list?context=5s`, {
      replace: true,
    })
  }, [searchParams])

  const onSavedQueryClicked = useCallback(
    (savedQuery: SavedQuery) => {
      savedQuery.onClick(savedQuery)
      update()
    },
    [tags]
  )

  const onFilterMenuClick = useCallback(
    (value: string | null) => {
      createTags(value)
    },
    [createTags]
  )

  return (
    <div className={style.container}>
      <div className={style.searchContainer}>
        <div className={style.searchContext}>
          <NLSAutocomplete
            token={token}
            savedQueries={savedQueries}
            onClearFilters={onClearFilters}
            onFilterMenuClick={onFilterMenuClick}
            onSavedQueryClick={(savedQuery) => {
              update()
              onSavedQueryClicked(savedQuery)
            }}
            onSaveButtonClick={() => setShowSaveQueryModal(true)}
            textFieldRef={textFieldRef}
          />
          <input
            className={classNames(
              style.contextInput,
              contextEdited ? style.contextInputEdited : undefined
            )}
            ref={contextRef}
            value={context}
            title={'Set context window'}
            onChange={onContextChange}
            onBlur={onContextFocusOut}
            onKeyDown={onContextKeyDown}
          />
        </div>
        <ToggleButton
          value={view}
          toggleButtons={[
            { icon: 'ViewList', value: ViewTypeValues.list },
            {
              icon: 'ViewGrid',
              value: ViewTypeValues.grid,
              disabled: !tags || tags?.length === 0,
            },
          ]}
          onChange={onViewChange}
          color={ToggleColors.green}
          type={ToggleButtonTypes.icon}
        />
        <ToggleButton
          value={pathnameValue}
          toggleButtons={[
            {
              text: 'Session logs',
              value: ViewTypeValues.sessionLogs,
              numberItem: totalSessions,
            },
            {
              text: 'Scenarios',
              value: ViewTypeValues.scenarios,
              disabled: tags?.length === 0,
            },
          ]}
          onChange={(value) =>
            navigate(`/dataset/${value}/${viewType}${location.search}`)
          }
          color={ToggleColors.green}
          type={ToggleButtonTypes.text}
        />
      </div>
      {tags && tags.length > 0 && (
        <div className={style.tagsContainer}>
          <div className={style.tags}>
            {tags.map((tag) => (
              <SearchQueryTag key={`${tag.key}`} tagKey={`${tag.key}`} />
            ))}
          </div>
        </div>
      )}
      {tags && (
        <SaveEditQueryDialog
          savedQueries={savedQueries}
          edit={savedQueryToEdit}
          open={showSaveQueryModal || savedQueryToEdit}
          onCancel={() => {
            setShowSaveQueryModal(false)
            setSavedQueryToEdit(null)
          }}
          tags={[...tags]}
          onConfirmed={async (result, tags) => {
            const params = Object.values(
              Object.fromEntries(
                Object.entries(result).filter(([key]) => key !== 'name')
              )
            )
            const data = parseSearchParams(params as string[])
            update(tags)

            if (savedQueryToEdit) {
              await editSavedQuery({
                token,
                userId: user?.sub,
                onAlert: setShowToast,
                queryId: savedQueryToEdit.id,
                query: {
                  name: result.name,
                  conditions: {
                    ...data,
                  },
                },
              })
              const queryTags = queryToTags(data.query)
                .concat(queryToTags(data.filters))
                .join('&')
              queryTags &&
                navigate(
                  `/dataset/scenarios/${viewType}?context=${context}&q=${encodeURIComponent(
                    queryTags
                  )}`,
                  { replace: true }
                )
              setSavedQueryToEdit(null)
            } else {
              await saveSearchQuery({
                token,
                userId: user?.sub,
                onAlert: setShowToast,
                searchQuery: {
                  name: result.name,
                  conditions: {
                    range,
                    ...data,
                  },
                },
              })
              setShowSaveQueryModal(false)
            }
            await getQueries()
          }}
        />
      )}
      <WarningDialog
        icon={null}
        isOpen={!!savedQueryToDelete}
        dialogContentText={
          <Typography type={TypographyTypes.title} size={TypographySizes.large}>
            Delete &quot;{savedQueryToDelete?.value}&quot;?
          </Typography>
        }
        dialogContentText2={`This action cannot be undone.`}
        dialogTitle={''}
        onSubmit={async () => {
          await deleteSavedQuery({
            token,
            queryId: savedQueryToDelete?.id,
            userId: user?.sub,
            onAlert: setShowToast,
          })
          await getQueries()
          setSavedQueryToDelete(undefined)
        }}
        onCancel={() => {
          setSavedQueryToDelete(undefined)
        }}
        buttons={{
          cancel: 'Cancel',
          submit: 'Delete',
        }}
      />
    </div>
  )
}

export default SearchQueryBar
