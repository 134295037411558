import {
  FILTER_VALUES_MATCHING_MULTI,
  FILTER_VALUES_MATCHING_MULTI_KEYS,
} from '../FilterMenu/consts'

const OPERATORS_MAPPING: Record<string, string> = {
  '=': '$eq',
  '!=': '$ne',
  '>': '$gt',
  '>=': '$gte',
  '<': '$lt',
  '<=': '$lte',
}

const OPERATORS_MAPPING_MULTI: Record<string, string> = {
  '=': '$in',
  '!=': '$nin',
}

const NUMBERS = [
  'bitrate',
  'score',
  'speed',
  'brake_pedal_score',
  'brake_pedal',
  'steering_angle',
  'gas_pedal',
  'acceleration',
  'max_speed',
  'lane_count',
  'score_brake-pedal',
  'score_gas-pedal',
  'score_steering-angle',
  'brake-pedal',
  'gas-pedal',
  'steering-angle',
  'lane-count',
  'max-speed',
]

const VALUE_MAPPING: Record<string, Record<string, string>> = {
  gear: {
    Drive: 'D',
    Neutral: 'N',
    Reverse: 'R',
    Park: 'P',
    Breaking: 'B',
  },
}

const QUERY_MAPPING: Record<string, string> = {
  annotation: 'incident',
  drive_id: 'session_id',
  road_attributes: 'map_feature',
  traffic_facilities: 'map_feature',
  traffic_junctions: 'map_feature',
  weather: 'condition',
  policy: 'drive_type',
  'turn-signal': 'turn_signal',
  'route-type': 'road_type',
  'route-facilities': 'map_feature',
  'route-attributes': 'map_feature',
  'route-junctions': 'map_feature',
  'driver-ID': 'driver_id',
  'canonical-ID': 'session_id',
  'hardware-ID': 'kit_id',
  'session-ID': 'session_id',
  organization: 'partner_id',
  'score_brake-pedal': 'brake_pedal_score',
  'score_gas-pedal': 'gas_pedal_score',
  'score_steering-angle': 'steering_angle_score',
  'brake-pedal': 'brake_pedal',
  'gas-pedal': 'gas_pedal',
  'steering-angle': 'steering_angle',
  'lane-count': 'lane_count',
  'max-speed': 'max_speed',
  'route-surface': 'road_surface',
}

const VALUE_UPPERCASE = [
  'kit_id',
  'drive_type',
  'policy',
  'turn_signal',
  'turn-signal',
  'road_attributes',
  'traffic_facilities',
  'traffic_junctions',
  'route-junctions',
  'route-facilities',
  'route-attributes',
]

const VALUE_ORIGINAL = ['hardware-ID']

const IS_FILTER = [
  'drive_type',
  'driver_id',
  'session_id',
  'bitrate',
  'kit_id',
  'drive_ID',
  'hardware-ID',
  'partner_id',
]

const shouldBeNumber = (type: string, value: string) =>
  NUMBERS.indexOf(type) !== -1 ? parseFloat(value) : value

const toCamelCase = (name: string) =>
  name
    .toLowerCase()
    .replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('_', ''))

const formatScore = (value: string) => {
  const score = parseFloat(value)
  return score > 1 ? score / 100 : score
}

export const parseSearchParams = (params: string[]): any => {
  return params.reduce(
    (acc, param) => {
      const [queryName, operator, ...rest] = param.split(' ')
      const value = rest.join(' ')
      if (queryName && queryName !== 'date') {
        const isMulti =
          FILTER_VALUES_MATCHING_MULTI[
            queryName as FILTER_VALUES_MATCHING_MULTI_KEYS
          ]
        const isScore = queryName.includes('score')

        const operatorMatching = isMulti
          ? OPERATORS_MAPPING_MULTI[operator as string]
          : OPERATORS_MAPPING[operator as string]
        const property = IS_FILTER.includes(
          QUERY_MAPPING[queryName] || queryName
        )
          ? 'filters'
          : 'query'
        acc[property][
          toCamelCase(QUERY_MAPPING[queryName] || queryName) as string
        ] =
          operator === 'x-y'
            ? {
                [OPERATORS_MAPPING['>=']]: parseFloat(value.split('-')[0]),
                [OPERATORS_MAPPING['<=']]: parseFloat(value.split('-')[1]),
              }
            : {
                [operatorMatching]: isScore
                  ? formatScore(value)
                  : isMulti
                  ? VALUE_UPPERCASE.includes(queryName)
                    ? value.toUpperCase().split(',')
                    : value.toLowerCase().split(',')
                  : shouldBeNumber(
                      queryName,
                      VALUE_MAPPING[queryName as string]
                        ? VALUE_MAPPING[queryName as string][value]
                        : VALUE_UPPERCASE.includes(queryName)
                        ? value?.toUpperCase()
                        : VALUE_ORIGINAL.includes(queryName)
                        ? value
                        : value?.toLowerCase()
                    ),
              }
      }
      return acc
    },
    {
      query: {},
      filters: {},
    } as any
  )
}

export const areParamsValid = (params: string) =>
  // Special case fix for 'Signs & lights'
  params
    .replace(' & ', ' # ')
    ?.split(/[&=><!]+/)
    .join('$')
    .split(/x-y/)
    .join('$')
    .split('$')
    .filter((text) => !['', ' ', '-'].includes(text)).length %
    2 ===
  0
