import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react'
import style from './style.less'
import Icon from '@yaak/components/src/Icon'
import { Version } from '@yaak/components/src/types'
import GridItemSelect, { useGridItemSelectStore } from './GridItemSelect'
import Typography, {
  TypographySizes,
  TypographyTypes,
} from '@yaak/components/src/Typography'
import { formatTime } from '@yaak/nutron/src/utils/time'
import Badge from '@yaak/components/src/Badge'
import { BadgeType } from '@yaak/components/src/Badge/Badge'
import DataStatus from '@yaak/admin/src/components/DataStatus'
import GridViewPlayer from './GridViewPlayer'
import {
  Scenario,
  SearchSessionData,
  SessionData,
} from '@yaak/components/services/api/api'
import {
  toDurationPrecise,
  toHoursMinutesSecondsFromSeconds,
} from '@yaak/admin/src/helpers/time'
import { getSessionStatus } from '@yaak/admin/src/helpers/drives'
import classNames from 'classnames'
import { useShallow } from 'zustand/react/shallow'
import Tooltip from '../Tooltip'
import { toastType, ToastTypes } from '../Toast/Toast'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ScalePosition } from '@yaak/nutron/src/utils/zoomComponent'
import { isSameYear, format } from 'date-fns'

// Mapping types similar to admin/src/helpers/drives.tsx#L149
const dataTypes: Record<string, number> = { 10: 2, 33: 2, 67: 2, '-1': 4 }

const GridItem = ({
  session,
  token,
  setShowToast,
}: {
  token: string
  session: SessionData | Scenario | SearchSessionData
  setShowToast: Dispatch<SetStateAction<toastType | null>>
}) => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const context = searchParams.get('context')
  const [pos, setPos] = useState<ScalePosition>({
    scale: 1,
    x: 0,
    y: 0,
  })
  const sensorTopics = useMemo(
    () => (session as Scenario).cameras?.map(({ name }) => name).sort(),
    [session]
  )

  const sensorTopicUrlMapping: Record<string, string> = useMemo(
    () =>
      (session as Scenario).cameras?.reduce(
        (acc, { name, url }) => ({ ...acc, [name]: url }),
        {}
      ),
    [session]
  )
  const { windowType: sensorTopic } = useGridItemSelectStore(
    useShallow((state) => ({
      windowType: state.windowType,
    }))
  )
  const sessionData = session as SessionData
  const sessionScenario = session as Scenario

  const redirectUrl = useMemo(() => {
    const id = sessionData.id || sessionScenario.sessionId
    const { startOffset, endOffset } = sessionScenario
    const params =
      startOffset !== undefined && endOffset !== undefined
        ? `?begin=${startOffset}&end=${endOffset}&context=${parseInt(
            context || '0'
          )}&offset=${
            startOffset > 0
              ? startOffset - parseInt(context || '0')
              : startOffset
          }`
        : ''
    return `/dataset/session-logs/${id}${params}`
  }, [])

  const onCopyIconClick = (event: React.MouseEvent<SVGSVGElement>) => {
    event.stopPropagation()
    const baseUrl = window.location.protocol + '//' + window.location.host
    navigator.clipboard.writeText(`${baseUrl}${redirectUrl}`)
    setShowToast({ text: 'Link copied to clipboard', type: ToastTypes.success })
  }

  const openDetails = () => {
    navigate(redirectUrl)
  }

  const resetZoom = useCallback(() => {
    setPos({ x: 0, y: 0, scale: 1 })
  }, [])

  const videoUrl = sensorTopicUrlMapping?.[sensorTopic] || ''

  const id =
    sessionData.id ||
    `${sessionScenario.sessionId}-${sessionScenario.startOffset}`

  const dataStatusResult = getSessionStatus({
    metadataStatus: sessionData.metadataStatus,
    fullVideoStatus: sessionData.fullVideoStatus,
    safetyAIStatus: sessionData.safetyAIStatus,
  })
  const showPopup = !!(
    sessionData.metadataStatus ||
    sessionData.fullVideoStatus ||
    sessionData.videoStatus
  )
  return (
    <div className={classNames(style.gridItem, 'gridViewItem')}>
      <div className={style.header}>
        <GridItemSelect sensorTopics={sensorTopics} />
        <Icon
          name="Refresh"
          version={Version.v2}
          color="color-neutral-040"
          onClick={resetZoom}
        />
      </div>
      <GridViewPlayer
        id={id}
        token={token}
        url={videoUrl}
        pos={pos}
        setPos={setPos}
        startOffset={sessionScenario.startOffset}
        endOffset={sessionScenario.endOffset}
        scenarios={(session as SearchSessionData).scenarios}
      />
      <div className={style.content} onClick={openDetails}>
        <div className={style.dateTime}>
          <Typography
            type={TypographyTypes.label}
            size={TypographySizes.large}
            version={Version.v2}
          >
            {isSameYear(new Date(session.startTimestamp), new Date())
              ? format(new Date(session.startTimestamp), 'dd MMM')
              : format(new Date(session.startTimestamp), 'dd MMM y')}
            {', '}
            {formatTime(session.startTimestamp, 0, true)}
          </Typography>
          <Tooltip text={'Copy link'} position={'top'}>
            <Icon
              className={style.copyIcon}
              name="Copy"
              version={Version.v2}
              onClick={onCopyIconClick}
            />
          </Tooltip>
        </div>
        <div className={style.badgeContainer}>
          <Badge
            label={
              sessionData.startTimestamp && sessionData.endTimestamp
                ? toDurationPrecise(
                    sessionData.startTimestamp,
                    sessionData.endTimestamp
                  )
                : toHoursMinutesSecondsFromSeconds(
                    sessionScenario.endOffset - sessionScenario.startOffset
                  )
            }
            type={BadgeType.grey}
            icon={
              <Icon
                name="Timer"
                color="color-neutral-040"
                version={Version.v2}
              />
            }
          />
          {(session as SearchSessionData).scenarios?.length > 0 && (
            <Badge
              label={(session as SearchSessionData).scenarios.length}
              type={BadgeType.blue}
              icon={
                <Icon
                  name="Scenario"
                  color="new-color-blue-036"
                  version={Version.v2}
                />
              }
            />
          )}
        </div>
        <div className={style.footer}>
          <Typography
            type={TypographyTypes.label}
            version={Version.v2}
            color="color-neutral-040"
          >
            {sessionScenario.sessionCanonicalName}
          </Typography>
          {showPopup && dataStatusResult.value !== 1 && (
            <div className={style.statusContainer}>
              <DataStatus
                data={dataStatusResult.data}
                dsAdmin={false}
                type={dataTypes[dataStatusResult.value] || 0}
                version={Version.v2}
                position="top"
                icon={
                  dataStatusResult.value === -1 ? (
                    <Badge label="Error" type={BadgeType.red} />
                  ) : (
                    <Badge label="Uploading" type={BadgeType.grey} />
                  )
                }
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default GridItem
