import React, { Dispatch, memo, SetStateAction, useMemo } from 'react'
import {
  Driver,
  MainContact,
  Partner,
  Scenario,
  SearchSessionData,
  SessionData,
  SimRigsData,
  SimulationDrive,
  User,
} from '../../services/api/api'
import { NavigateFunction } from 'react-router-dom'
import Icon, { IconSizes } from '../Icon'
import appStyle from '@yaak/admin/src/style.less'
import { toastType, ToastTypes } from '../Toast/Toast'
import AvatarNamePlate from '../AvatarNamePlate'
import Badge from '../Badge'
import { BadgeType } from '../Badge/Badge'
import Score from '@yaak/ds-admin/src/components/Score/Score'
import { toHoursAndMinutes } from '@yaak/ds-admin/src/helpers/helpers'
import style from '@yaak/ds-admin/src/pages/StudentsPage/style.less'
import classNames from 'classnames'
import ExamResultComponent from '@yaak/ds-admin/src/components/ExamResult/ExamResult'
import Typography, { TypographyTypes } from '../Typography'
import { FormattedMessage } from 'react-intl'
import { Version } from '../types'
import { weatherConditionsMapping } from './helpers'
import Tooltip from '../Tooltip'
import gridStyle from './style.less'
import { capitalize } from '@yaak/nutron/src/utils'

const mainContactRow = (mainContact: MainContact | null) =>
  mainContact ? (
    <AvatarNamePlate
      name={`${mainContact.firstName} ${mainContact.lastName}`}
      gravatarURL={mainContact.profileImageURL}
    />
  ) : (
    '—'
  )

const driverInstructorRow = (driver: Driver) =>
  driver ? (
    <AvatarNamePlate
      name={`${driver.firstname} ${driver.lastname}`}
      gravatarURL={driver.profileImageURL}
    />
  ) : (
    '—'
  )

const GridNameElement = ({
  data,
  ellipsis,
  tooltip,
  navigate,
}: {
  data: SimRigsData
  tooltip: string
  ellipsis: boolean
  navigate: NavigateFunction
}) => {
  return {
    text: data.name,
    tooltip: ellipsis ? data.name : tooltip,
    ellipsis,
    click: (event: React.MouseEvent) => {
      event.stopPropagation()
      event.preventDefault()
      navigate(`/simulatorrigs/${data.id}`)
    },
  }
}

const GridPartnerElement = ({
  partner,
  ellipsis,
  navigate,
}: {
  partner?: Partner | null
  ellipsis?: boolean
  navigate?: NavigateFunction
}) => {
  return {
    text: partner ? partner.name : '-',
    tooltip: ellipsis
      ? partner?.name
      : partner?.name
      ? 'View partner profile'
      : '',
    ellipsis: true,
    click: (event: React.MouseEvent) => {
      event.stopPropagation()
      event.preventDefault()
      partner && navigate?.(`/partners/${partner.id}/drives`)
    },
  }
}

const GridVehicleElement = ({
  vin,
  licensePlate,
  dongleId,
  ellipsis,
  navigate,
  dsAdmin,
}: {
  vin?: string
  licensePlate?: string
  dongleId?: string
  ellipsis: boolean
  navigate?: NavigateFunction
  dsAdmin?: boolean
}) => {
  return {
    text: licensePlate || vin || '-',
    tooltip: vin && navigate && 'View vehicle profile',
    ellipsis,
    click: (event: React.MouseEvent) => {
      event.stopPropagation()
      event.preventDefault()
      dsAdmin
        ? navigate?.(`/vehicles/${dongleId}`)
        : navigate?.(`/vehicles/${vin}`)
    },
  }
}

const GridKitElement = ({
  id,
  ellipsis,
  navigate,
}: {
  id?: string
  ellipsis: boolean
  navigate?: NavigateFunction
}) => {
  return {
    text: id || '-',
    tooltip: ellipsis ? id : navigate && 'View kit profile',
    ellipsis: true,
    click: (event: React.MouseEvent) => {
      event.stopPropagation()
      event.preventDefault()
      id && navigate?.(`/kits/details/${id}`)
    },
  }
}

const GridDriverElement = ({
  driver,
  ellipsis,
  navigate,
  dsAdmin,
  driveType,
  version,
}: {
  driver: Driver | null
  ellipsis: boolean
  navigate?: NavigateFunction
  dsAdmin?: boolean
  driveType?: number
  version?: Version
}) => {
  return {
    text: driver ? driverInstructorRow(driver) : driver || '-',
    tooltip: driver
      ? `${driver?.firstname} ${driver?.lastname}`
      : navigate && 'View user profile',
    ellipsis,
    version,
    click: (event: React.MouseEvent) => {
      event.stopPropagation()
      event.preventDefault()
      driver &&
        navigate?.(
          dsAdmin
            ? driveType === 2
              ? `/students/${driver.id}`
              : `/instructors/${driver.id}`
            : `/users/${driver.id}`
        )
    },
  }
}

const GridInstructorElement = ({
  session,
  ellipsis,
  navigate,
  dsAdmin,
}: {
  session: SessionData
  ellipsis: boolean
  navigate?: NavigateFunction
  dsAdmin: boolean
}) => {
  const instructor = session.instructor
    ? `${session.instructor?.firstname} ${session.instructor?.lastname}`
    : '-'
  return {
    text: session.instructor
      ? driverInstructorRow(session.instructor)
      : instructor,
    tooltip: session.instructor && instructor,
    ellipsis,
    click: (event: React.MouseEvent) => {
      event.stopPropagation()
      event.preventDefault()
      session.instructor &&
        navigate?.(
          dsAdmin
            ? session.driveType === 2
              ? `/students/${session.instructor.id}`
              : `/instructors/${session.instructor.id}`
            : `/users/${session.instructor.id}`
        )
    },
  }
}

const GridMainContactElement = ({
  partner,
  navigate,
}: {
  partner: Partner | null
  navigate: NavigateFunction
}) => {
  return {
    text: partner && mainContactRow(partner.mainContact),
    tooltip: partner?.mainContact && 'View user profile',
    click: (event: React.MouseEvent) => {
      event.stopPropagation()
      event.preventDefault()
      partner && navigate?.(`/users/${partner.mainContactId}`)
    },
  }
}

const GridTextElement = ({
  text,
  ellipsis = false,
  version,
}: {
  text: string | React.ReactNode
  ellipsis?: boolean
  version?: Version
}) => {
  return {
    text,
    ellipsis,
    tooltip: ellipsis && text,
    version,
  }
}

export type GridCopyActionData =
  | SimRigsData
  | Partner
  | SessionData
  | User
  | SimulationDrive
  | Scenario
  | SearchSessionData

const CopyIcon = ({
  text,
  data,
  copyText,
  setShowToast,
  version,
}: {
  data: GridCopyActionData
  setShowToast: Dispatch<SetStateAction<toastType | null>>
  text?: string
  copyText?: string
  version?: Version
}) => {
  return (
    <Icon
      name={'Copy'}
      version={version}
      size={IconSizes.medium}
      color={version === Version.v2 ? 'color-neutral-040' : ''}
      className={appStyle.pointer}
      onClick={(event: React.MouseEvent<SVGSVGElement>) => {
        event.stopPropagation()
        event.preventDefault()
        navigator.clipboard.writeText(
          copyText ||
            (
              data as
                | SimRigsData
                | Partner
                | SessionData
                | User
                | SimulationDrive
            ).id ||
            (data as Scenario).sessionId
        )
        setShowToast({ text: text || `ID Copied`, type: ToastTypes.success })
      }}
    />
  )
}

const MemoCopyIcon = memo(CopyIcon)

const GridCopyIconElement = ({
  data,
  name,
  setShowToast,
  text,
  tooltipText,
  copyText,
  version,
}: {
  data: GridCopyActionData
  name: string
  setShowToast: Dispatch<SetStateAction<toastType | null>>
  text?: string
  tooltipText?: string
  copyText?: string
  version?: Version
}) => {
  return {
    icon: true,
    tooltip: tooltipText || `Copy ${name} ID`,
    text: (
      <MemoCopyIcon
        text={text}
        setShowToast={setShowToast}
        data={data}
        version={version}
        copyText={copyText}
      />
    ),
  }
}

const GridWeatherIcons = ({
  weatherConditions,
}: {
  weatherConditions: string[]
}) => {
  const weatherIcons = useMemo(() => {
    const iconMap = weatherConditions.reduce(
      (prev: Record<string, string[]>, curr) => {
        const key = weatherConditionsMapping[curr]
        return { ...prev, [key]: prev[key] ? [...prev[key], curr] : [curr] }
      },
      {}
    )
    return Object.keys(iconMap).map((key: string) => ({
      icon: key,
      tooltip: iconMap[key].join(', '),
    }))
  }, [weatherConditions])
  return (
    <div className={gridStyle.weatherContainer}>
      {weatherIcons.map(({ icon, tooltip }) => (
        <Tooltip key={icon} text={tooltip} position={'top'}>
          <div className={gridStyle.icon}>
            <Icon name={icon} version={Version.v2} />
          </div>
        </Tooltip>
      ))}
    </div>
  )
}

export const MemoGridWeatherIcons = memo(GridWeatherIcons)

const GridLightningBadge = ({
  lightningConditions,
}: {
  lightningConditions: string[]
}) => {
  const badgeColors: Record<string, string> = {
    day: BadgeType.yellow,
    night: BadgeType.grey,
    transition: BadgeType.purple,
  }
  return (
    <div className={gridStyle.weatherContainer}>
      {lightningConditions.map((lightningCondition) => (
        <Badge
          key={lightningCondition}
          label={capitalize(lightningCondition)}
          type={badgeColors[lightningCondition]}
        />
      ))}
    </div>
  )
}

export const MemoGridLightningBadge = memo(GridLightningBadge)

export type GridEditActionData = Partner | SimRigsData | User | string

const EditIcon = ({
  data,
  editAction,
}: {
  data: GridEditActionData
  editAction: (data: any) => void
}) => {
  const edit = (event: any) => {
    event.stopPropagation()
    event.preventDefault()
    editAction(data)
  }
  return (
    <Icon
      name={'Edit'}
      className={appStyle.pointer}
      onClick={edit}
      size={IconSizes.medium}
    />
  )
}

const MemoEditIcon = memo(EditIcon)

const GridEditIconElement = ({
  data,
  editAction,
  name,
  message,
}: {
  data: GridEditActionData
  editAction: (data: any) => void
  name?: string
  message?: React.ReactNode
}) => {
  return {
    icon: true,
    hidden: true,
    tooltip: message || `Edit ${name}`,
    text: <MemoEditIcon data={data} editAction={editAction} />,
  }
}

const GridDeleteIconElement = ({
  data,
  deleteAction,
  tooltip,
  visible = false,
}: {
  data: User | SimulationDrive
  deleteAction: (data: any) => void
  tooltip: string
  visible?: boolean
}) => {
  return {
    icon: true,
    hidden: !visible,
    tooltip,
    text: (
      <Icon
        name={'Delete'}
        className={classNames(
          appStyle.pointer,
          visible ? appStyle.iconRed : undefined
        )}
        onClick={(event) => {
          event.stopPropagation()
          event.preventDefault()
          deleteAction(data)
        }}
        size={IconSizes.medium}
      />
    ),
  }
}

const GridActiveElement = ({ condition }: { condition: boolean }) => {
  return {
    text: condition ? (
      <Badge label={'Active'} withDot />
    ) : (
      <Badge label={'Inactive'} type={BadgeType.grey} withDot />
    ),
  }
}

const GridIconElement = ({
  tooltip,
  text,
  version,
}: {
  tooltip?: string
  text: string | React.ReactNode
  version?: Version
}) => {
  return {
    icon: true,
    tooltip,
    text,
    version,
  }
}

const GridDriveStatusElement = ({
  session,
  activeDrivesIds,
}: {
  session: SessionData
  activeDrivesIds: string[]
}) => {
  return {
    text:
      activeDrivesIds.indexOf(session.id) !== -1 ? (
        <Icon
          name={'ArrowForward'}
          styles={{ color: '#0070cc' }}
          size={IconSizes.medium}
        />
      ) : (
        <Icon
          name={'Success'}
          styles={{ color: '#00A751' }}
          size={IconSizes.medium}
        />
      ),
    tooltip:
      activeDrivesIds.indexOf(session.id) !== -1 ? 'In progress' : 'Completed',
    icon: true,
  }
}

const BitRateGridElement = ({ session }: { session: SessionData }) => {
  return GridIconElement({
    tooltip: session.bitRateKbps ? session.bitRateKbps.toString() : '-',
    text: session.bitRateKbps ? (
      session.bitRateKbps >= 8000 ? (
        <Badge label={`${(session.bitRateKbps / 1024).toFixed(0)} Mbps`} />
      ) : (
        <Badge
          label={`${(session.bitRateKbps / 1024).toFixed(0)} Mbps`}
          type={BadgeType.yellow}
        />
      )
    ) : (
      '-'
    ),
  })
}

const GridUserElement = ({
  user,
  ellipsis,
  navigate,
}: {
  navigate?: NavigateFunction
  user: User
  ellipsis?: boolean
}) => {
  return {
    text: (
      <AvatarNamePlate
        name={`${user.firstName} ${user.lastName}`}
        gravatarURL={user.profileImageURL}
      />
    ),
    tooltip: ellipsis
      ? `${user.firstName} ${user.lastName}`
      : 'Open user profile',
    click: () => navigate?.(`/users/${user.id}`),
  }
}

export const GridScoreElement = ({ user }: { user: User }) => {
  return GridIconElement({
    text: user.rating ? (
      <Score active={user.active} score={user.rating} />
    ) : (
      <></>
    ),
  })
}

const GridTotalTimeElement = ({ time }: { time?: number }) => {
  return GridTextElement({
    text: toHoursAndMinutes(time),
  })
}

const GridSuccessCancelIconElement = ({
  condition,
  positive,
  negative,
}: {
  condition?: boolean
  positive: string
  negative: string
}) => {
  return GridIconElement({
    tooltip: condition ? positive : negative,
    text: condition ? (
      <Icon
        className={style.success}
        name={'Success'}
        size={IconSizes.medium}
      />
    ) : (
      <Icon className={style.cancel} name={'Cancel'} size={IconSizes.medium} />
    ),
  })
}

interface ExamCellOptions {
  setOpenAddExamResults: (value: boolean) => void
  setExamResultsFor: (value: User) => void
}

const hasExamResults = (user: User): boolean => {
  return user.examResults ? user.examResults.length > 0 : false
}

const passedExam = (user: User): boolean =>
  user?.examResults?.at(-1)?.passed ?? false

const examCell = (user: User, options: ExamCellOptions) => (
  <div
    className={classNames(
      style.addExam,
      user.active ? style.hideOnOver : undefined
    )}
  >
    <div className={style.examResultContainer}>
      <ExamResultComponent
        passedAnExam={hasExamResults(user)}
        userActive={user.active}
        success={passedExam(user)}
      />
    </div>
    {user.active && (
      <Typography
        type={TypographyTypes.link}
        className={style.addExamLink}
        onClick={(event) => {
          event.stopPropagation()
          options.setOpenAddExamResults(true)
          options.setExamResultsFor(user)
        }}
      >
        <FormattedMessage id={'add_exam'} />
      </Typography>
    )}
  </div>
)

const GridExamCellElement = ({
  user,
  options,
}: {
  user: User
  options: ExamCellOptions
}) => {
  return GridIconElement({
    text: examCell(user, options),
  })
}

const isUserAdminInstructor = (user: User) =>
  user.roles?.indexOf('ds-admin') !== -1 &&
  user.roles?.indexOf('ds-instructor') !== -1

const GridStartElement = ({ user }: { user: User }) => {
  return GridIconElement({
    text: isUserAdminInstructor(user) ? (
      <Icon name={'StarFill'} styles={{ color: '#E5B300' }} />
    ) : (
      <></>
    ),
  })
}

export {
  GridStartElement,
  GridExamCellElement,
  GridNameElement,
  GridPartnerElement,
  GridVehicleElement,
  GridKitElement,
  GridDriverElement,
  GridInstructorElement,
  GridMainContactElement,
  GridTextElement,
  GridCopyIconElement,
  GridDeleteIconElement,
  GridActiveElement,
  GridIconElement,
  GridDriveStatusElement,
  BitRateGridElement,
  GridEditIconElement,
  GridSuccessCancelIconElement,
  GridTotalTimeElement,
  GridUserElement,
  GridWeatherIcons,
  GridLightningBadge,
}
