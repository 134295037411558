export const findQueryById = (savedQueries: any, id: string) =>
  savedQueries.filter((query: any) => query.id === id)[0]

export const OPERATORS_MAPPING: Record<string, string> = {
  $eq: '=',
  $in: '=',
  $nin: '!=',
  $ne: '!=',
  $gt: '>',
  $gte: '>=',
  $lt: '<',
  $lte: '<=',
}

export const GROUPS = {
  ENVIRONMENT: 'ENVIRONMENT',
  ROUTE_INFRASTRUCTURE: 'ROUTE INFRASTRUCTURE',
  ROUTE_TOPOGRAPHY: 'ROUTE TOPOGRAPHY',
  SESSION_INFO: 'SESSION INFO',
  TELEMETRY: 'TELEMETRY',
  TRIAGE: 'TRIAGE',
}

export type OPERATORS_KEYS =
  | 'hardware-ID'
  | 'bitrate'
  | 'driver-ID'
  // | 'location'
  | 'score'
  | 'lighting'
  | 'weather'
  | 'steering-angle'
  | 'brake-pedal'
  | 'gas-pedal'
  | 'gear'
  | 'speed'
  | 'acceleration'
  | 'turn-signal'
  | 'max-speed'
  | 'route-facilities'
  | 'route-junctions'
  | 'route-attributes'
  | 'route-type'
  | 'lane-count'
  | 'route-surface'
  | 'canonical-ID'
  | 'date'
  | 'organization'
  | 'policy'
  | 'session-ID'
  | 'annotation'
  | 'score_brake-pedal'
  | 'score_gas-pedal'
  | 'score_steering-angle'

export const GROUPS_MAPPING: Record<OPERATORS_KEYS, string> = {
  'hardware-ID': GROUPS.SESSION_INFO,
  bitrate: GROUPS.SESSION_INFO,
  'driver-ID': GROUPS.SESSION_INFO,
  // location: GROUPS.SESSION_INFO,
  score: GROUPS.TRIAGE,
  lighting: GROUPS.ENVIRONMENT,
  weather: GROUPS.ENVIRONMENT,
  'steering-angle': GROUPS.TELEMETRY,
  'gas-pedal': GROUPS.TELEMETRY,
  'brake-pedal': GROUPS.TELEMETRY,
  gear: GROUPS.TELEMETRY,
  speed: GROUPS.TELEMETRY,
  acceleration: GROUPS.TELEMETRY,
  'turn-signal': GROUPS.TELEMETRY,
  'max-speed': GROUPS.ROUTE_INFRASTRUCTURE,
  'route-facilities': GROUPS.ROUTE_INFRASTRUCTURE,
  'route-junctions': GROUPS.ROUTE_INFRASTRUCTURE,
  'route-attributes': GROUPS.ROUTE_INFRASTRUCTURE,
  'route-surface': GROUPS.ROUTE_TOPOGRAPHY,
  'route-type': GROUPS.ROUTE_TOPOGRAPHY,
  'lane-count': GROUPS.ROUTE_INFRASTRUCTURE,
  'canonical-ID': GROUPS.SESSION_INFO,
  date: GROUPS.SESSION_INFO,
  organization: GROUPS.SESSION_INFO,
  policy: GROUPS.SESSION_INFO,
  annotation: GROUPS.TRIAGE,
  'session-ID': GROUPS.SESSION_INFO,
  'score_brake-pedal': GROUPS.TRIAGE,
  'score_gas-pedal': GROUPS.TRIAGE,
  'score_steering-angle': GROUPS.TRIAGE,
}

export type UNIT_MAPPING_KEYS = 'bitrate'

export const UNIT_MAPPING: Record<UNIT_MAPPING_KEYS, string> = {
  bitrate: 'mbps',
}

const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)

const titleCase = (str: string) =>
  str[0].toUpperCase() + str.slice(1).toLowerCase()

const QUERY_MAPPING: Record<string, string> = {
  incident: 'annotation',
  session_id: 'canonical-ID',
  map_feature: 'route-attributes',
  // TODO: The issue: https://app.shortcut.com/yaak/story/30842/nutron-filter-management-issue-when-saving-search-query-that-is-map-feature
  // map_feature: 'route-facilities',
  // map_feature: 'route-junctions',
  condition: 'weather',
  drive_type: 'policy',
  turn_signal: 'turn-signal',
  road_type: 'route-type',
  driver_id: 'driver-ID',
  kit_id: 'hardware-ID',
  partner_id: 'organization',
  brake_pedal_score: 'score_brake-pedal',
  gas_pedal_score: 'score_gas-pedal',
  steering_angle_score: 'score_steering-angle',
  brake_pedal: 'brake-pedal',
  gas_pedal: 'gas-pedal',
  steering_angle: 'steering-angle',
  lane_count: 'lane-count',
  max_speed: 'max-speed',
  road_surface: 'route-surface',
}

export const queryToTags = (query: any) => {
  const result: string[] = []
  if (query) {
    const keys = Object.keys(query)
    if (keys.length > 0) {
      keys.forEach((key) => {
        const operators = Object.keys(query[key])
        const operator = operators?.[0]
        if (
          operators.length === 2 &&
          ['$gte', '$lte'].every((v) => operators.includes(v))
        ) {
          const minVal = query[key].$gte
          const maxVal = query[key].$lte
          result.push(`${camelToSnakeCase(key)} x-y ${minVal}-${maxVal}`)
        } else if (query[key][operator]) {
          const value = query[key][operator]
          result.push(
            `${QUERY_MAPPING[camelToSnakeCase(key)] || camelToSnakeCase(key)} ${
              OPERATORS_MAPPING[operator]
            } ${typeof value === 'string' ? titleCase(value) : value}`
          )
        }
      })
    }
  }
  return result
}
